var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "background"
  }, [_c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('b-navbar', {
    staticClass: "header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header')])], 2), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('div', {
    staticClass: "d-flex flex-column align-items-center text-center"
  }, [_c('span', {
    staticClass: "text-center",
    staticStyle: {
      "font-size": "2rem",
      "color": "black"
    }
  }, [_c('b', [_c('span', {
    staticClass: "text-primary"
  }, [_vm._v("Edukasi, Tips Belajar, dan Inspirasi")])])]), _c('br'), _c('b-form-group', {
    staticClass: "blog-search mt-4",
    staticStyle: {
      "width": "100%",
      "max-width": "350px"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "search-input",
      "placeholder": "Search here"
    },
    model: {
      value: _vm.q,
      callback: function callback($$v) {
        _vm.q = $$v;
      },
      expression: "q"
    }
  }), _c('b-input-group-append', {
    staticClass: "cursor-pointer",
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "container",
    staticStyle: {
      "padding-left": "6vw",
      "padding-right": "6vw",
      "margin-top": "3rem",
      "margin-bottom": "1.5rem"
    }
  }, [_c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_vm.dataArtikel.length < 1 ? _c('div', {
    staticClass: "text-center mt-5 mb-5"
  }, [_c('b-img', {
    attrs: {
      "src": require('@/assets/images/elp/empty.png'),
      "fluid": "",
      "width": "150px"
    }
  }), _c('h5', {
    staticClass: "text-primary"
  }, [_vm._v("Mading tidak ditemukan!!")])], 1) : _c('b-row', {
    staticClass: "blog-list-wrapper"
  }, [_vm._l(_vm.currentPageItems, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('div', {
      staticClass: "article-wrapper mb-4"
    }, [_c('b-link', {
      attrs: {
        "to": {
          name: 'artikel-detail',
          params: {
            slug: item.slug
          }
        }
      }
    }, [_c('b-img', {
      staticClass: "article-image img-fluid",
      attrs: {
        "src": "https://cdn.britannica.com/85/13085-050-C2E88389/Corpus-Christi-College-University-of-Cambridge-England.jpg",
        "alt": "Artikel image"
      }
    })], 1), _c('div', {
      staticClass: "text-left mt-2"
    }, [item.category ? _c('b-badge', {
      staticClass: "mb-1",
      attrs: {
        "variant": item.category.color
      }
    }, [_vm._v(" " + _vm._s(item.category.name) + " ")]) : _vm._e(), _c('b-link', {
      staticClass: "blog-title-truncate text-dark",
      attrs: {
        "to": {
          name: 'artikel-detail',
          params: {
            slug: item.slug
          }
        }
      }
    }, [_c('h4', {
      staticClass: "article-title"
    }, [_c('b', [_vm._v(_vm._s(_vm.getFormattedTitle(item.title)))])])]), _c('p', {
      staticClass: "text-muted mt-1"
    }, [_vm._v(_vm._s(new Date(item.created_at).toLocaleDateString()))])], 1)], 1)]);
  }), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "my-2"
  }, [_c('b-pagination', {
    attrs: {
      "align": "center",
      "total-rows": _vm.rows,
      "per-page": _vm.filters.perPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.page,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "page", $$v);
      },
      expression: "filters.page"
    }
  })], 1)])], 2)], 1)])]), _c('footer-home')], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }