  <template>
    <div class="background">
      <div id="user-profile">
        <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
          <slot name="navbar">
            <profile-header />
          </slot>
        </b-navbar>
        <b-overlay :show="loading">
          <div class="mb-1">
            <div class="d-flex flex-column align-items-center text-center">
              <span class="text-center" style="font-size: 2rem; color: black">
                <b><span class="text-primary">Edukasi, Tips Belajar, dan Inspirasi</span></b>
              </span>
              <br />
              <b-form-group class="blog-search mt-4" style="width: 100%; max-width: 350px;">
                <b-input-group class="input-group-merge">
                  <b-form-input id="search-input" v-model="q" placeholder="Search here" />
                  <b-input-group-append class="cursor-pointer" is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="container"
            style="padding-left: 6vw; padding-right: 6vw; margin-top: 3rem; margin-bottom: 1.5rem;">
            <section id="card-images">
              <div class="text-center mt-5 mb-5" v-if="dataArtikel.length < 1">
                <b-img :src="require('@/assets/images/elp/empty.png')" fluid width="150px" />
                <h5 class="text-primary">Mading tidak ditemukan!!</h5>
              </div>
              <b-row class="blog-list-wrapper" v-else>
                <b-col v-for="(item, i) in currentPageItems" :key="i" cols="12" md="6">
                  <div class="article-wrapper mb-4">
                    <b-link :to="{ name: 'artikel-detail', params: { slug: item.slug } }">
                      <b-img
                        src="https://cdn.britannica.com/85/13085-050-C2E88389/Corpus-Christi-College-University-of-Cambridge-England.jpg"
                        class="article-image img-fluid" alt="Artikel image" />
                    </b-link>

                    <div class="text-left mt-2">
                      <b-badge class="mb-1" :variant="item.category.color" v-if="item.category">
                        {{ item.category.name }}
                      </b-badge>

                      <b-link :to="{ name: 'artikel-detail', params: { slug: item.slug } }"
                        class="blog-title-truncate text-dark">
                        <h4 class="article-title">
                          <b>{{ getFormattedTitle(item.title) }}</b>
                        </h4>
                      </b-link>

                      <p class="text-muted mt-1">{{ new Date(item.created_at).toLocaleDateString() }}</p>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12">
                  <div class="my-2">
                    <b-pagination v-model="filters.page" align="center" :total-rows="rows" :per-page="filters.perPage"
                      first-number last-number prev-class="prev-item" next-class="next-item">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </b-col>
              </b-row>
            </section>
          </div>
        </b-overlay>
        <footer-home />
      </div>
    </div>
  </template>


  <script>
    import {
      BRow,
      BCol,
      BCard,
      BFormInput,
      BCardText,
      BCardTitle,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BImg,
      BCardBody,
      BLink,
      BBadge,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BPagination,
      VBTooltip,
      BCardGroup,
      BOverlay,
      //   BNavbar,
      //   BContainer,
    } from "bootstrap-vue";
    import {
      kFormatter
    } from "@core/utils/filter";
    import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
    import ProfileHeader from "./components/Navbar.vue";
    import FooterHome from "./components/Footer.vue";
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

    export default {
      components: {
        BRow,
        BCol,
        BCard,
        BFormInput,
        BCardText,
        BCardBody,
        BCardTitle,
        BCardGroup,
        BOverlay,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BLink,
        BBadge,
        BFormGroup,
        BInputGroup,
        BInputGroupAppend,
        BImg,
        BPagination,
        ContentWithSidebar,
        // BNavbar,
        // BContainer,
        ProfileHeader,
        FooterHome,
      },
      data() {
        return {
          search_query: "",
          blogList: [],
          blogSidebar: {},
          currentPage: 1,
          perPage: 1,
          rows: 1,
          q: null,
          filters: {
            page: 1,
            perPage: 4,
          },
          paginated_items: {},
          currentPageIndex: 0,
          nbPages: 0,
          dataArtikel: [],
          loading: false,
        };
      },
      directives: {
        "b-tooltip": VBTooltip,
        Ripple,
      },
      watch: {
        q: {
          handler(value) {
            if (this.q == "") {
              this.q = null;
            } else {
              this.getData();
            }
          },
          deep: true,
        },
      },
      created() {
        this.getData();
      },
      computed: {
        currentPageItems() {
          let lengthAll = this.dataArtikel.length;
          this.nbPages = 0;
          for (let i = 0; i < lengthAll; i = i + this.filters.perPage) {
            this.paginated_items[this.nbPages] = this.dataArtikel.slice(
              i,
              i + this.filters.perPage
            );
            this.nbPages++;
          }

          return this.paginated_items[this.filters.page - 1];
        },
      },
      methods: {
        kFormatter,
        tagsColor(tag) {
          if (tag === "Quote") return "light-info";
          if (tag === "Gaming") return "light-danger";
          if (tag === "Fashion") return "light-primary";
          if (tag === "Video") return "light-warning";
          if (tag === "Food") return "light-success";
          return "light-primary";
        },
        convertShortText(htmlString, numLimit) {
          const clearString = this.removeTags(htmlString);
          return this.truncateString(clearString, numLimit);
        },
        getData() {
          this.loading = true;
          let params = {
            search: this.q,
            type: "article",
          };
          this.$store
            .dispatch("artikel/publicIndex", params)
            .then((res) => {
              const artikel = res.data.data;
              const result = artikel.map((item) => ({
                ...item,
                text: item.content ? this.convertShortText(item.content, 70) : "",
              }));
              this.loading = false;
              this.dataArtikel = result;
              this.rows = result.length;
            })
            .catch((err) => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Error `,
                  icon: "XIcon",
                  variant: "danger",
                  text: err.message,
                },
              });
            });
        },
        getFormattedTitle(title) {
          var count = 40;
          var result = title.slice(0, count) + (title.length > count ? "..." : "");
          return result;
        },
      },
    };
  </script>

  <style lang="scss">
    .background {
      background: linear-gradient(to bottom, #cce8ed 15%, #ffffff 85%);
    }


    .article-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      /* Pastikan kontainer mengikuti lebar penuh */
    }

    .article-image {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 8px;
    }

    .article-title {
      margin: 0;
      padding: 0;
      font-size: 1.25rem;
      white-space: normal;
      /* Biarkan teks wrapping */
      word-wrap: break-word;
      /* Agar kata panjang terputus dengan baik */
      overflow-wrap: break-word;
      overflow: hidden;
      /* Hapus teks yang berlebihan */
      max-width: 100%;
      /* Pastikan mengikuti lebar kontainer gambar */
    }



    .text-left {
      text-align: left;
    }
  </style>